import React, { Component } from 'react';
import Web3  from "web3";
import './App.css';
import  MTFinance from "../abis/MTFinance.json";
import  MTSToken  from "../abis/MTSToken.json";
import  MTFTokenFarm from "../abis/MTFTokenFarm.json";
import Navbar from './Navbar';
import Main from './Main';
import Footer from './Footer';

class App extends Component {

  async componentWillMount(){
	 try{
			await this.loadWeb3()
	 }catch(error){
		  window.alert('Non Ethereum browser detected. You should try considering Metamask!')
	}
	
	try{
			await this.loadBlockchainData()
	}catch(error){
	
	}
  }

  async loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    }
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    } 
    else {
      window.alert('Non Ethereum browser detected. You should try considering Metamask!')
    }
  }

  async loadBlockchainData(){
    const web3 = window.web3;	
    // Fetch accounts
    const accounts = await web3.eth.getAccounts()

    this.setState({ account: accounts[0] })
    
    // Fetch NetworkID
    const networkId = await web3.eth.net.getId();
    
    // Load MTFToken Contract
    //const mtfTokenData = MTFinance.networks[networkId];
  //if (mtfTokenData) {
      const mtfToken = new web3.eth.Contract(MTFinance.abi,"0xBFD8CC2D30c2f2Db71f853D47c6E6ea4fe33D53F");
      this.setState({ mtfToken })
      let mtfTokenBalance = await mtfToken.methods.balanceOf(this.state.account).call() 
      this.setState({ mtfTokenBalance: mtfTokenBalance.toString() })
    //} else {
     // window.alert('MTFinance contract not deployed to detected network')      
   //}

    // Load MTSToken Contract
   // const mtsTokenData = MTSToken.networks[networkId];
    //if (mtsTokenData) {
      const mtsToken = new web3.eth.Contract(MTSToken.abi,"0xa7f35CBa1febCe8efc24D16fA06587868b94A872");
      this.setState({ mtsToken })
      let mtsTokenBalance = await mtsToken.methods.balanceOf(this.state.account).call() 
      this.setState({ mtsTokenBalance: mtsTokenBalance.toString() })
   // } else {
   //   window.alert('MTSToken Contract not deployed to detected network')      
   // }

    // Load TokenFarm Contract
   // const tokenFarmData = MTFTokenFarm.networks[networkId];
   // if (tokenFarmData) {
      const tokenFarm = new web3.eth.Contract(MTFTokenFarm.abi,"0x9DB16288F0fc5d27ce66aA7d7B4992AF5C20D3CA");
      this.setState({ tokenFarm })
      
	  let stakingBalance = await tokenFarm.methods.yourStakedTokens(this.state.account).call() 
      this.setState({ stakingBalance: stakingBalance.toString() })
	  
	  let claimableMTFReward = await tokenFarm.methods.fetchPendingReward(this.state.account).call() 
      this.setState({ claimableMTFReward: claimableMTFReward.toString() })
	  
	  let calculateMSPEarnings = await tokenFarm.methods.calculateMSPEarnings(this.state.account).call() 
      this.setState({ calculateMSPEarnings: calculateMSPEarnings.toString() })
	  
	  //let rewardPool = await tokenFarm.methods.rewardPool().call() 
      //this.setState({ rewardPool: rewardPool.toString() })
	  
	  let rewardPool = await tokenFarm.methods.totalStakes().call() 
      this.setState({ rewardPool: rewardPool.toString() })
	  
	 
   // } else {
   //   window.alert('MTFTokenFarm Contract not deployed to detected network')      
   // }
	
	//  Now we can Set Loading to false
    this.setState({ loading: false })
  }
  
  approveSpender = (amount) => {
    this.setState({ loading: true })
    this.state.mtfToken.methods.approve(this.state.tokenFarm._address, amount).send({ from: this.state.account }).on('transactionHash', (hash) => {
              this.setState({ loading: false })
    })
  }
  
  stakeTokens = (amount) => {
    this.setState({ loading: true })   
      this.state.tokenFarm.methods.stakeTokens(amount).send({ from: this.state.account }).on('transactionHash', (hash) => {
        this.setState({ loading: false })
    })
  }

  unstakeTokens = (amount) => {
    this.setState({ loading: true })
    this.state.tokenFarm.methods.unstakeFromPool(amount).send({ from: this.state.account }).on('transactionHash', (hash) => {
      this.setState({ loading: false })
    })
  }
  
  claimStakingReward = (amount) => {
    this.setState({ loading: true })
    this.state.tokenFarm.methods.claimStakingReward().send({ from: this.state.account }).on('transactionHash', (hash) => {
      this.setState({ loading: false })
    })
  }
  
  claimMSPRewards = (amount) => {
    this.setState({ loading: true })
    this.state.tokenFarm.methods.claimMSPRewards(amount).send({ from: this.state.account }).on('transactionHash', (hash) => {
      this.setState({ loading: false })
    })
  }
 
 

  constructor(props){
    super(props)
    this.state = {
      account: '0x0',
      betaToken: {},
      mtsToken: {},
      tokenFarm: {},
      mtfTokenBalance: '0',
      mtsTokenBalance: '0',
      stakingBalance: '0',
	  claimableMTFReward:'0',
	  calculateMSPEarnings:'0',
	  rewardPool:'0',
      loading: true//need to make it true
    }

  }
  render(){
    let content
    if (this.state.loading) {
      content = <h1 id="loading" className="text-center">Loading....</h1>
    } else {
      content = <Main
        mtfTokenBalance={this.state.mtfTokenBalance}
        mtsTokenBalance={this.state.mtsTokenBalance}
        stakingBalance={this.state.stakingBalance}
        stakeTokens={this.stakeTokens}
        unstakeTokens={this.unstakeTokens}
		claimStakingReward={this.claimStakingReward}
		claimMSPRewards={this.claimMSPRewards}
		claimableMTFReward={this.state.claimableMTFReward}
		calculateMSPEarnings={this.state.calculateMSPEarnings}
		rewardPool={this.state.rewardPool}
		approveSpender={this.approveSpender}
      />
      
    }


    return (
	
      <div className="App" style={{
        backgroundColor: '#f2f2f2'
      }}>
        <Navbar account={ this.state.account } />
        <div className="container-fluid mt-5">
          <div className="row">
            <main role="main" className="col-lg-12 ml-auto mr-auto" style={{ maxWidth: '840px' }}>
              <div className="content mr-auto ml-auto">
                {content}
              </div>
            </main>
          </div>
        </div>
 	<Footer/>
      </div>
    );
  }
}

export default App;
