import React, { Component } from 'react';
import yield_farming from "../yield_farming.png"
import twitter from "../twitter.png";
import medium from "../medium.png";
import telegram from "../telegram.png";
import youtube from "../youtube.png";
import github from "../github.png";
class Navbar extends Component {

  render(){
    return (
      <nav style={{backgroundColor:'black'}}>
        <a className="navbar-brand" href="https://meliorate.finance" target="_blank">
            &nbsp; Meliorate Staking!!&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </a>
		 <a href="https://twitter.com/melioratefinan1"  target="_blank"><img src= {twitter} width="40" height="40" class="d-inline-block align-top"/>            
            &nbsp;
        </a>
	<a href="https://t.me/finance_meliorate"  target="_blank"><img src= {telegram} width="40" height="40" class="d-inline-block align-top"/>            
            &nbsp;
        </a>
	<a href="https://www.youtube.com/channel/UCVfrO3xhHb7Cg-XDqMiW1EQ"  target="_blank"><img src= {youtube} width="70" height="50" class="d-inline-block align-top"/>            
            &nbsp;
        </a>
	
	<a href="https://github.com/MeliorateFinance"  target="_blank"><img src= {github} width="40" height="40" class="d-inline-block align-top"/>            
            
        </a>
        <a href="https://Meliorate-finance.medium.com/"  target="_blank"><img src= {medium} width="70" height="50" class="d-inline-block align-top"/>            
            &nbsp;
        </a>
	
        <ul className="navbar-nav px-3">
          <li className="nav-item text-nowrap d-none d-sm-none d-sm-block">
            <div className="badge badge-primary text-wrap">Your wallet address
              <big id="account">  { this.props.account}</big>
            </div>
          </li>
        </ul>
		
		      </nav>
    );
  }
}

export default Navbar;
