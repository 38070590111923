import React, { Component } from 'react';
import twitter from "../twitter.png";
import medium from "../medium.png";
import telegram from "../telegram.png";
import youtube from "../youtube.png";
import github from "../github.png";
import logo from "../yield_farming.png";
class Footer extends Component {

  render(){
    return (
      <nav style={{backgroundColor:'black'}}>
	
    <a href="https://twitter.com/melioratefinan1" target="_blank"><img src= {twitter} width="40" height="40" class="d-inline-block align-right"/>            
            &nbsp;
        </a>
	<a href="https://t.me/finance_meliorate" target="_blank"><img src= {telegram} width="40" height="40" class="d-inline-block align-right"/>            
            &nbsp;
        </a>
	<a href="https://www.youtube.com/channel/UCVfrO3xhHb7Cg-XDqMiW1EQ" target="_blank"><img src= {youtube} width="70" height="50" class="d-inline-block align-right"/>            
            &nbsp;
        </a>
	
	<a href="https://github.com/MeliorateFinance" target="_blank"><img src= {github} width="40" height="40" class="d-inline-block align-right"/>            
            
        </a>
    <a href="https://Meliorate-finance.medium.com/" target="_blank"><img src= {medium} width="70" height="50" class="d-inline-block align-right"/>            
            &nbsp;
        </a>
	
      </nav>
    );
  }
}

export default Footer;
